const edgeType = 'custom';

export const initialEdges = [
//   { 
//     id: 'e1-2',
//     source: 'connectionline-1',
//     target: 'connectionline-2',
//     type: edgeType,
//     animated: true 
// },
//   { 
//     id: 'e1-3',
//     source: 'connectionline-1',
//     target: 'connectionline-3',
//     type: edgeType,
//     animated: true 
// },
//   { 
//     id: 'e1-4',
//     source: 'connectionline-1',
//     target: 'connectionline-4',
//     type: edgeType,
//     animated: true 
// },
//   { 
//     id: 'e1-5',
//     source: 'connectionline-1',
//     target: 'connectionline-5',
//     type: edgeType,
//     animated: true 
// },
];
