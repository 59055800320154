import TestFlow from "./components/flowTest/TestFlow";

function App() {
  return (
   <div style={{ width: "1200px", height: "780px" }}>
    <TestFlow />
   </div>
  );
}

export default App;
